@viewport {
  width: device-width;
  initial-scale: 1.0;
}

body {
  overflow-x: auto;
  margin: 0;
  padding: 0;
}


.App {
  background-color: #607d92;
  font-style: italic;
  text-align: center;
  font-family: "Boogaloo";
  width: 100vw;
  min-width: 280px; 
}
@media (max-width: 900px) {
  .App {
    font-size: 14px;
    min-width: 0;
    max-width: 100vw;
    max-height: 100vh;
    overflow-x: scroll;
  }
}

.home-page {
  background-color: #bef2f7;
  padding: 20px;
  height: 100vh;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 1em;
  background-color: rgb(79, 147, 190);
  white-space: nowrap;
}


.nav-link {
  margin:  .5em;
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;
  padding: 0.5em .5em;
  border: 2px solid white;
  border-radius: 20px;
}

@media only screen and (max-width: 900px) {
  .nav-link {
    font-size: .5em;
  }
}

.nav-link:hover {
  color: darkgreen;
  background-color: white;
}


/* Styling for About Me Component */

.about-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 2em;
  text-align: center;
}

.about-page h3 {
  font-size: 1.5rem;
  line-height: 1.3;
  margin: 1em;
  text-shadow: 1px 1px 3px rgb(127, 106, 144);
}

/* Resume page styling  */



/* Contact Page Styling  */

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
}

.contact-card {
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 2em;
}

.contact-card button {
  border-radius: 20px;
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 2rem;
  width: 80%;
  height: 100%;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
}

.contact-card button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  color:white;
  background-color: rgb(71, 63, 115);
}

/* project component styling  */

.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 2em;
}

.project-card {
width: 100%;
height: auto;
margin: 20px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
background-color: grey;
padding: 30px;
border: 6px solid black;
border-radius: 15px;
box-shadow: 10px 10px 5px rgb(26, 26, 26);
color: white;
text-decoration: none;
}

.project-card h3 {
margin-top: 0;
font-size: 18px;
}

.project-card img {
border-radius: 15px;
box-shadow: 5px 5px 5px rgb(26, 26, 26);
margin-bottom: 5px;
max-width: 100%;
height: auto;
}

.project-card p {
margin: 5px 0;
line-height: 1.4;
font-size: 18px;
text-align: justify;
}

@media only screen and (min-width: 900px) {
.nav-link {
  margin:  .5em;
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out;
  padding: 0.5em .5em;
  border: 2px solid white;
  border-radius: 20px;
}
}


@media only screen and (min-width: 900px) {
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em;
  background-color: rgb(79, 147, 190);
}
}

@media only screen and (min-width: 900px) {
.project-container {
display: flex;
flex-wrap: wrap;
width: 100%;
max-width: 1200px;
margin: 0 auto;
}
}

/* CSS rules for screens smaller than 768px */
@media (max-width: 1200px) {
  body {
    font-size: 14px;
  }
}
